/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import moment from 'moment'
import { Link, graphql } from 'gatsby'

import BaseLayout from '../layouts/BaseLayout'
import SearchWidget from '../components/widgets/search/search'
import TagWidget from '../components/widgets/tag/tag'
import SEO from '../components/seo'
import useScript from '../util/UseScript'

export const query = graphql`
  query GetPost($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      fields {
        slug
      }
      frontmatter {
        title
        tag
        image
      }
      correctedDateEpoch
      html
      excerpt(truncate: true)
    }
  }
`

const Post = ({ data, htmlContent }: {
  data: any
  htmlContent?: any
}) => {
  const fm = data.markdownRemark.frontmatter
  const title = fm.title || data.markdownRemark.fields.slug || 'New Post'
  const tags = fm.tag || []
  const description = data.markdownRemark.excerpt || ''
  const isPreview = data.isPreview || false
  const myAttr = {
    'repo': 'poipoii/ot-dev-comments',
    'issue-term': 'pathname',
    'label': 'comments',
    'theme': 'github-light',
    'crossOrigin': 'anonymous',
  }

  const previewImg: any = {}
  if (!isPreview) {
    useScript('https://poipoii.github.io/ot-dev-comments/client.js', '#comments', myAttr)
  } else {
    previewImg['src'] = fm.image
  }

  return (
    <BaseLayout
      isPreview={isPreview}>
    {
      isPreview ? null : (
        <SEO
          title={title}
          description={description}
          image={fm.image}
        />
      )
    }
      <div key={title} id="colorlib-main">
        <section className="ftco-section ftco-no-pt ftco-no-pb">
          <div className="container">
            <div className="row d-flex">
              <div className="col-lg-8 px-md-5 py-5">
                <div className="row pt-md-4">
                  {fm.image ? (
                    <img className="img-fluid lazyload" data-src={fm.image} alt={title} {...previewImg}/>
                  ) : null}

                  {/* <h1 className="mb-3">{title}</h1> */}
                  <p className="font-italic w-100">{moment(data.markdownRemark.correctedDateEpoch).format('LL')}</p>

                  {
                    isPreview ? (
                      <div className="w-100">{htmlContent}</div>
                    ) : (
                      <div className="w-100" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
                    )
                  }
                  {tags && tags.length ? (
                    <div className="tag-widget post-tag-container mb-3 mt-3 w-100">
                      <div className="tagcloud">
                        {tags.map((tag: string) => {
                          return (
                            <Link key={tag} className="tag-cloud-link" to={`/tag/${tag.toLowerCase()}`}>{tag}</Link>
                          )
                        })}
                      </div>
                    </div>
                  ) : null}

                  <div className="w-100" id="comments">
                  </div>
                </div>
              </div>
              <div className="col-lg-4 sidebar ftco-animate fadeInUp ftco-animated bg-light pt-5">
                {
                  isPreview ? null : (
                    <React.Fragment>
                      <div className="sidebar-box pt-md-4">
                        <SearchWidget></SearchWidget>
                      </div>
                      <div className="sidebar-box ftco-animate fadeInUp ftco-animated">
                        <TagWidget></TagWidget>
                      </div>
                    </React.Fragment>
                  )
                }
              </div>
            </div>
          </div>
        </section>
      </div>
    </BaseLayout>
  )
}

export default Post
