import { useEffect } from 'react';

const useScript = (url: string, target: any, options: any) => {
  const isClient = typeof window === 'object';

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    if (options && typeof options === 'object') {
      for (const key of Object.keys(options)) {
        script.setAttribute(key, options[key]);
      }
    }

    const comp = target ? document.querySelector(target) : document.body;
    if (!comp) {
      return undefined;
    }
    comp.appendChild(script);

    return () => {
      try {
        comp.removeChild(script);
      } catch (error) {}
    }
  }, [url]);
};

export default useScript;
